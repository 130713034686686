<template>
  <div>
    <div v-if="this.isAuthenticated">
      <KTCard ref="preview" v-bind:title="title" v-bind:example="true">
        <template v-slot:title>
          {{ $t("AUTH.USER_PROFILE.TITLE_CHANGE_PASS") }}
        </template>
        <template v-slot:body>
          <br>
          <br>

          <div id="app">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6 col-12">
                  <b-form class="form" @submit.stop.prevent="onSubmit">
                  <b-form-group id="email-group" label="Email" label-for="email">
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        id="email"
                        name="email"
                        v-model="$v.form.email.$model"
                        readonly
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"
                        placeholder="Email address"
                    ></b-form-input>

                    <b-form-invalid-feedback id="email-live-feedback">
                        Email is required and a valid email address.
                    </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                    id="passwordOld-group"
                    label="Old Password"
                    label-for="passwordOld"
                    >
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        type="password"
                        id="passwordOld"
                        name="passwordOld"
                        v-model="$v.form.passwordOld.$model"
                        :state="validateState('passwordOld')"
                        aria-describedby="passwordOld-live-feedback"
                        placeholder="Old Password"
                        value=""
                    ></b-form-input>

                    <b-form-invalid-feedback id="passwordOld-live-feedback">
                      Old Password is required.
                    </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                    id="password-group"
                    label="New Password"
                    label-for="password"
                    >
                    <b-form-input
                        class="form-control form-control-solid h-auto py-5 px-6"
                        type="password"
                        id="password"
                        name="password"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="password-live-feedback"
                        placeholder="Password"
                    ></b-form-input>

                    <b-form-invalid-feedback id="password-live-feedback">
                        Password is required.
                    </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                        id="password-confirm-group"
                        label="Confirm Password"
                        label-for="confirmpassword"
                    >
                      <b-form-input
                          class="form-control form-control-solid h-auto py-5 px-6"
                          type="password"
                          id="passwordconfirm"
                          name="passwordconfirm"
                          v-model="$v.form.passwordconfirm.$model"
                          :state="validateState('passwordconfirm')"
                          aria-describedby="password-confirm-live-feedback"
                          placeholder="Confirm Password"
                      ></b-form-input>

                      <b-form-invalid-feedback id="password-live-feedback">
                        Password unmatch.
                      </b-form-invalid-feedback>
                    </b-form-group>


                  <div class="row">
                    <!--begin::Action-->
                    <div class="form-group d-flex flex-wrap flex-center">
                      <button
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                          ref="kt_login_signup_submit"
                      >
                        Submit
                      </button>
                      <button
                          class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                          v-on:click="cancelChange()"
                      >
                        Cancel
                      </button>
                      <!--end::Action-->
                    </div>
                  </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
          <br>




        <b-modal ref="my-modal" size="sm">
          <div class="d-block text-center">
            <h3>
              {{ $t("AUTH.USER_PROFILE.SUBMIT_EDIT") }}
            </h3>
          </div>
          <template #modal-footer="{  }">
            <b-button size="lg" variant="danger" @click="hideModal()">
              TUTUP
            </b-button>
          </template>
        </b-modal>

        <b-modal ref="my-modal2" size="sm">
          <div class="d-block text-center">
            <h3>
              {{ $t("AUTH.USER_PROFILE.ERROR_EDIT") }}
            </h3>
          </div>
          <template #modal-footer="{ close }">
            <b-button size="lg" variant="danger" @click="close()">
              TUTUP
            </b-button>
          </template>
        </b-modal>
      
        </template>
      </KTCard>
    </div>
    <div v-if="!this.isAuthenticated">
      <div class="card card-custom card-stretch gutter-b">
        <div class="card-body d-flex p-0">
          <div
            class="flex-grow-1 p-20 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
            style="background-position: calc(100% + 0.5rem) bottom; background-size: 35% auto; background-image: url(media/svg/humans/custom-10.svg)"
          >
            <h2 class="text-dark pb-5 font-weight-bolder">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}
            </h2>
            <p class="text-dark-50 pb-5 font-size-h5">
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW") }}<br />
              {{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_SECOND_PARAGRAPH") }}
            </p>
            <a
              @click="registerClicked"
              class="btn btn-danger font-weight-bold py-2 px-6"
              >{{ $t("COMPLAINT_RECEIPT_DATA.REGISTER_NOW_TITLE") }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_EDUCATION,
  GET_GENDER,
  GET_IDENTITY_TYPE,
  //SET_AUTH,
  // REGISTER,
  SET_EDUCATION,
  //SET_ERROR,
  // SET_ERROR,
  SET_GENDER,
  SET_IDENTITY_TYPE
  //, SET_LOADING_LOGIN, SET_SUCCESS_ACTION, SET_USER_INFO
} from "@/core/services/store/auth.module";
import KTCard from "@/view/content/Card.vue";
import { validationMixin } from "vuelidate";
import {email, required, minLength, sameAs} from "vuelidate/lib/validators";
import * as axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "profile",
  data() {
    return {
      editmode : false,
      url: null,
      title: this.$t("MENU.PROFILE"),
      pathIdentity: "",
      pathProfile: "",

      form: {
        email: "",
        passwordOld: "",
        password: "",
        passwordconfirm: "",
        userName: "",
        fullName: "",
        birthPlace: "",
        birthday: "",
        gender: null,
        education: null,
        identityType: null,
        identityNo: "",
        identityFile: null,
        phone: "",
        address: null,
        idimage: null,
        profileimage: null,
      },
      logo: process.env.BASE_URL + "media/logos/komnasham.png",
      educations: [],
      gender: [],
      identityType: [],
      userInfo: null
    };
  },
  validations: {
    form: {
      userName: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      passwordOld: {
        required
      },
      password: {
        required,
        minLength: minLength(3)
      },
      passwordconfirm: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.PERSONAL_INFORMATION"),
        route: "profile"
      }
    ]);
    this.$store.dispatch(GET_EDUCATION);
    this.$store.dispatch(GET_GENDER);
    this.$store.dispatch(GET_IDENTITY_TYPE);
    this.bindingData();
    this.getUserData();
    console.log(this.pathIdentity);
  },
  methods: {
    getViewData(){
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get("user/smartmapinfo")
            .then(({ response }) => {
              this.$v.form.email.$model = response.data[0].email;
              this.$v.form.fullName.$model = response.data[0].name;
              this.$v.form.identityNo.$model = response.data[0].identity_no;
              this.$v.form.birthPlace.$model = response.data[0].birth_place;
              this.$v.form.identityType.$model = response.data[0].identity_type_id;
              this.$v.form.birthday.$model = response.data[0].birth_date;
              this.$v.form.idimage.$model = response.data[0].identity_file;
              // this.$v.form.profileimage.$model = response.data[0].photo_file;
              this.$v.form.education.$model = response.data[0].education_id;
              this.$v.form.phone.$model = response.data[0].phone_no;
              this.$v.form.gender.$model = response.data[0].gender;
              this.$v.form.address.$model = response.data[0].address;

              this.pathIdentity = response.data[0].identity_file;
              resolve(response);
            })
            .catch(({ error }) => {
              reject(error);
            });
      });
    },
    getUserData() {
      axios({
        url:
            process.env.VUE_APP_API_BASE_URL + "api/user/smartmapinfo",
        method: "GET",
        headers: {
          Authorization: 'Bearer ' + JwtService.getToken()
        }
      }).then((response) => {
        this.$v.form.email.$model = response.data.data[0].email;
        this.$v.form.fullName.$model = response.data.data[0].name;
        this.$v.form.identityNo.$model = response.data.data[0].identity_no;
        this.$v.form.birthPlace.$model = response.data.data[0].birth_place;
        this.$v.form.identityType.$model = response.data.data[0].identity_type_id;
        this.$v.form.birthday.$model = response.data.data[0].birth_date;
        this.$v.form.idimage.$model = response.data.data[0].identity_file;
        // this.$v.form.profileimage.$model = response.data.data[0].photo_file;
        this.$v.form.education.$model = response.data.data[0].education_id;
        this.$v.form.phone.$model = response.data.data[0].phone_no;
        this.$v.form.gender.$model = response.data.data[0].gender;
        this.$v.form.address.$model = response.data.data[0].address;

        this.pathIdentity = response.data.data[0].identity_file;
        this.url = this.createDownloadUrl(response.data.data[0].photo_file)
      }).catch(({ error }) => {
        this.userInfo = JSON.parse(window.localStorage.getItem("user_info"));
        if(this.userInfo != null){
          this.getParseData(this.userInfo);
        }else{
          console.log(error);
        }
      });
    },
    getParseData(response){
      this.$v.form.email.$model = response.data[0].email;
      this.$v.form.fullName.$model = response.data[0].name;
      this.$v.form.identityNo.$model = response.data[0].identity_no;
      this.$v.form.birthPlace.$model = response.data[0].birth_place;
      this.$v.form.identityType.$model = response.data[0].identity_type_id;
      this.$v.form.birthday.$model = response.data[0].birth_date;
      this.$v.form.idimage.$model = response.data[0].identity_file;
      // this.$v.form.profileimage.$model = response.data[0].photo_file;
      this.$v.form.education.$model = response.data[0].education_id;
      this.$v.form.phone.$model = response.data[0].phone_no;
      this.$v.form.gender.$model = response.data[0].gender;
      this.$v.form.address.$model = response.data[0].address;

      this.pathIdentity = response.data[0].identity_file;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    bindingData() {
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === SET_EDUCATION) {
          this.educations = this.getEducations;
        }
        if (mutation.type === SET_GENDER) {
          this.gender = this.getGender;
        }
        if (mutation.type === SET_IDENTITY_TYPE) {
          this.identityType = this.getIdentityType;
        }
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        username: null,
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    cancelChange() {
      this.$router.push("profile");
    },
    chooseFile(event) {
      let input = event.target.files[0];
      let validFormat = this.validate_fileupload(input);
      if (validFormat == false) {
          Swal.fire("Format File", "File format not supported", "error");
          return;
        }
    },
    validate_fileupload(fileName) {
      var allowed_extensions = new Array(
          "jpg",
          "png",
          "gif",
          "jpeg"
      );
      var file_extension = fileName.split(".").pop().toLowerCase();

      for (var i = 0; i <= allowed_extensions.length; i++) {
        if (allowed_extensions[i] == file_extension) {
          return true; // valid file extension
        }
      }
      return false;
    },
    onSubmit() {
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      let formEditProfile = new FormData();

      formEditProfile.set("email", this.$v.form.email.$model);
      formEditProfile.set("old_password", this.$v.form.passwordOld.$model);
      formEditProfile.set("password", this.$v.form.password.$model);
      axios({
        url:
            process.env.VUE_APP_API_BASE_URL + "api/smartmap/update_password",
        method: "POST",
        data : formEditProfile,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: 'Bearer ' + JwtService.getToken()
        }
      })
        .then((response) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          console.log(response.data);
          if(response.data.message == 'failed'){
            Swal.fire("Update password failed", response.data.data[0], "error");
          }else if(response.data.message == 'success'){
            Swal.fire("Update password successfully", "Password has been updated", "success");
            this.$router.push("profile");
          }else{
            Swal.fire("Update password failed", "Server not response", "error");
          }
        }).catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        Swal.fire("Update password failed", "Server not response", "error");
        });;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.getUserData();
      return;
    },
  },
  components: {
    KTCard
  },
  computed: mapGetters([
    "isAuthenticated",
    "getEducations",
    "getGender",
    "getIdentityType"
  ])
};
</script>

